<template>
    <div class="adsCon">
        <div class="adsEditWrap">
            <div class="title">
                <div>已生成5个广告</div>
                <div class="mainPage">
                    选择主页：
                    <el-dropdown>
                        <span class="el-dropdown-link pageSelect">
                            <span>
                                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                                <span>KI-KI-TOWM</span>
                            </span>
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item  class="msg">
                                <div class="pageImg">
                                    <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                                </div>
                                <div class="pageMsg">
                                    <p>KI-KI-TOWM</p>
                                    <div>编号：4562312656256</div>
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="adsContent">
                <div class="adsList">
                    <ad-item v-for="item in 5" :key="item"></ad-item>
                </div>
            </div>
        </div>
         <div class="rightTools">
             <!-- <div>
                 <div class="batchOperation">
                    <el-switch v-model="batch"></el-switch> <span>批量操作已关闭</span>
                    <p>对全部广告设计创意</p>
                </div>
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item title="模板" name="1">
                        <div class="templateList">
                            <div class="tempItem">
                                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                            </div>
                            <div class="tempItem">
                                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                            </div>
                            <div class="tempItem">
                                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                            </div>
                            <div class="tempItem">
                                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                            </div>
                        </div>
                        <el-form v-model="tempForm" label-position="top">
                            <el-form-item label="商品图缩放">
                                <el-slider v-model="tempForm.scal"></el-slider>
                            </el-form-item>
                            <el-form-item label="图片填充">
                                <el-radio-group v-model="tempForm.fill" size="mini">
                                    <el-radio-button label="居中裁剪"></el-radio-button>
                                    <el-radio-button label="尺寸扩展"></el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="">
                                <el-radio-group v-model="tempForm.completion" size="mini">
                                    <el-radio label="毛玻璃"></el-radio>
                                    <el-radio label="纯色补全"></el-radio>
                                    <el-radio label="无"></el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="文字颜色">
                                <el-color-picker v-model="tempForm.color"></el-color-picker>
                            </el-form-item>
                            <el-form-item label="文字内容">
                                <el-input v-model="tempForm.content"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
                        <el-collapse-item title="角标" name="2">
                            <div class="templateList">
                                <div class="tempItem">
                                    <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                                </div>
                                <div class="tempItem">
                                    <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                                </div>
                                <div class="tempItem">
                                    <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                                </div>
                                <div class="tempItem">
                                    <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                                </div>
                            </div>
                            <el-form v-model="tempForm" label-position="top">
                                <el-form-item label="角标位置">
                                    <el-radio-group v-model="tempForm.fill" size="mini">
                                        <el-radio-button label="左上角"></el-radio-button>
                                        <el-radio-button label="右上角"></el-radio-button>
                                        <el-radio-button label="右下角"></el-radio-button>
                                        <el-radio-button label="左下角"></el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </el-collapse-item>
                    <el-collapse-item title="文案" name="3">
                        <el-form v-model="tempForm" label-position="top">
                            <el-form-item label="广告正文">
                                <el-select v-model="tempForm.adCon" multiple placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="广告标题">
                                <el-select v-model="tempForm.adTitle" multiple placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="广告内容">
                                <el-select v-model="tempForm.adMsg" multiple placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="行动号召">
                                <el-select v-model="tempForm.actionType" multiple placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </el-collapse-item>
                </el-collapse>
             </div> -->
             <div>
                 <div class="btnGroup"></div>
                 <draggable class="list-group" tag="ul" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false">
                    <el-collapse v-model="activeName" accordion>
                        <transition-group type="transition" :name="'flip-list'">
                            <el-collapse-item :title="element.value" :name="i+1" class="list-group-item" v-for="(element,i) in options" :key="i+''">
                                <div>{{element.label}}</div>
                            </el-collapse-item>
                        </transition-group>
                    </el-collapse>
                </draggable>
             </div>
         </div>
    </div>
</template>
<script>
import adItem from './adItem'
import draggable from 'vuedraggable'
    export default {
        data() {
            return {
                page:'',
                batch:false,
                activeName:"1",
                tempForm:{},
                options: [
                    {
                    value: '选项1',
                    label: '黄金糕'
                    }, {
                    value: '选项2',
                    label: '双皮奶'
                    }, {
                    value: '选项3',
                    label: '蚵仔煎'
                    }, {
                    value: '选项4',
                    label: '龙须面'
                    }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
            }
        },
        components:{
            adItem,
            draggable
        },
        methods: {
            // 监听拖拽
            onMove({ relatedContext, draggedContext }) {
                const relatedElement = relatedContext.element;
                const draggedElement = draggedContext.element;
                return (
                    (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
                );
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: !this.editable,
                    ghostClass: "ghost"
                };
            },
        },
    }
</script>
<style lang="scss" scoped>
.flip-list-move {
        -webkit-transition: -webkit-transform 0.5s;
        transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
        transition: transform 0.5s, -webkit-transform 0.5s;
    }
    .no-move {
        -webkit-transition: -webkit-transform 0s;
        transition: -webkit-transform 0s;
        transition: transform 0s;
        transition: transform 0s, -webkit-transform 0s;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    .list-group {
        min-height: 20px;
        width: 100%;
        overflow-y: auto;
            /*滚动条样式*/
        &::-webkit-scrollbar {
            width: 6px;    
            // height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(0,0,0,0.1);
        }
        span{
            white-space: nowrap;
        }
    }
    .list-group-item {
        cursor: move;
        // display: inline-block;
        // vertical-align: top;
        // margin-right: 10px;
        // height: 420px;
        box-sizing: border-box;
        .scrollItem{
            width: 246px;
        }
        .addItem{
            text-align: center;
            padding: 158px 10px;
            border: 1px dashed #ddd;
            cursor: pointer;
        }
    }
    .list-group-item i {
            cursor: pointer;
    }
.templateList{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .tempItem{
        width: 120px;
        height: 120px;
        margin: 5px;
        cursor: pointer;
        border: 1px solid transparent;
        &.active{
            border: 1px solid #409EFF;
        }
        .el-image{
            width: 100%;
            height: 100%;
        }
    }
}
.el-dropdown-link.pageSelect{
    display: flex;
    align-items: center;
    span{
        display: flex;
        align-items: center;
    }
    .el-image{
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}
.el-dropdown-menu__item.msg{
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    height: 50px;
    padding: 5px;
    .pageImg{
        width: 40px;
        height: 40px;
        .el-image{
            width: 100%;
            height: 100%;
        }
    }
    .pageMsg{
        padding-left: 10px;
        flex: 1;
        overflow: hidden;
        p{
            font-size: 16px;
        }
        div{
            font-size: 12px;
            color: #999;
            margin-top: 10px;
        }
    }
}
.adsCon{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    .rightTools{
        width: 320px;
        background: #fff;
        margin-left: 20px;
        padding: 20px;
        height: 820px;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: 0;
        &::-webkit-scrollbar {
            width: 6px;    
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(0,0,0,0.1);
        }
        .batchOperation{
            font-size: 14px;
            margin-bottom: 20px;
            p{
                margin-top: 5px;
                color: #999;
            }
        }
    }
    .adsEditWrap{
        flex: 1;
        background: #fff;
        padding: 20px;
        height: 760px;
        overflow-y: auto;
        margin-right: 340px;
        &::-webkit-scrollbar {
            width: 6px;    
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            background: rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            border-radius: 0;
            background: rgba(0,0,0,0.1);
        }
    }
    //  margin-left: 20px;
    .title{
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 14px;
    }
    .adsList{
        display: flex;
        align-items: center;
        padding: 20px 0;
        margin: 5px;
        flex-wrap: wrap;
    }
    .mainPage{
        line-height: 20px;
        
    }
}
</style>