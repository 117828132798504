<template>
    <div>
        <div class="adsWrap">
        <div class="leftLine">
            <div class="target">
                <i class="el-icon-s-opportunity"></i>
                <div class="msg">
                    <p>投放策略</p>
                    <div>获取新客户</div>
                </div>
            </div>
            <el-steps direction="vertical" :active="activeStep">
                <el-step title="选择商品" @click.native="setStep(1)"></el-step>
                <el-step title="广告创意" @click.native="setStep(2)"></el-step>
                <el-step title="目标客户" @click.native="setStep(3)"></el-step>
                <el-step title="投放预算" @click.native="setStep(4)"></el-step>
            </el-steps>
            <el-button type="primary" size="small" @click="closeCreateStandardAd" class="close">关闭</el-button>
        </div>
        <div class="rightCon">
          <div>
            <!-- <select-goods></select-goods> -->
            <ads></ads>
          </div>
          <div class="btnGroup">
            <el-button type="primary" size="small" @click="prev" v-if="activeStep>=2">上一步</el-button>
            <el-button type="primary" size="small" @click="next"  v-if="activeStep <= 3">下一步</el-button>
            <el-button type="primary" size="small" @click="save" v-if="activeStep == 4">保存</el-button>
          </div>
        </div>
    </div>
    </div>
</template>
<script>
import selectGoods from './components/selectGoods';
import ads from './components/ads';
    export default {
        data() {
            return {
                activeStep:1
            }
        },
        components:{
            selectGoods,
            ads
        },
        methods: {
            // 关闭创建广告
            closeCreateStandardAd() {
                this.$store.dispatch('app/openSideBar')
                this.$router.push({name:'adManagement'})
            },
            // 上一步
            prev(){},
            // 下一步
            next(){},
            // setStep
            setStep(v){
                this.activeStep = v;
            }
        },
        mounted() {
            this.$store.dispatch('app/closeSideBar');
        },
        beforeDestroy() {
            this.$store.dispatch('app/openSideBar');
        }
    }
</script>

<style lang="scss" scope>
    .adsWrap{
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        .btnGroup{
            margin-top: 30px;
        }
        .leftLine{
            background: #fff;
            padding: 20px;
            flex: 200px 0 0;
            height: 88vh;
            position: relative;
            .close{
                position: absolute;
                right: 20px;
                bottom: 20px;
            }
            .target{
                color: #999;
                font-size: 20px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                .msg{
                    margin-left: 15px;
                    p{
                        font-size: 14px;
                        // margin-bottom: 10px;
                    }
                    div{
                        color: #333;
                        font-size: 16px;
                    }
                }
            }
        }
        .el-steps--vertical{
            height: 50%;
            .el-step{
            cursor: pointer;
            }
        }
        .rightCon{
            flex: 1;
            // overflow: hidden;
            margin-left: 20px;
            max-height: 88vh;
            // padding: 20px;
            // max-height: 800px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 6px;    
                height: 6px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                background: rgba(0,0,0,0.2);
            }
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
                border-radius: 0;
                background: rgba(0,0,0,0.1);
            }
        }
        }
</style>