<template>
    <div class="selectGoodsWrap">
        <p class="title">选择你要推广的商品</p>
        <div class="formWrap">
            <el-form :inline="true" size="small">
                <el-form-item label="">
                    <el-select v-model="formInline.cat" placeholder="全部商品类别">
                        <el-option label="全部广告系列" value="campaign"></el-option>
                        <el-option label="全部广告组" value="adset"></el-option>
                        <el-option label="全部广告" value="ad"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="formInline.cat" placeholder="选择几个最吸引人的商品投放"></el-input>
                </el-form-item>
                <el-form-item label="筛选">
                    <el-col :span="6">
                        <el-form-item prop="date1" style="margin-bottom:0">
                            <el-input v-model="formInline.cat" placeholder="最低价格"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col class="line" :span="1">~</el-col>
                    <el-col :span="6">
                        <el-form-item prop="date2" style="margin-bottom:0">
                            <el-input v-model="formInline.cat" placeholder="最高价格"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <div>
                    <el-form-item>
                        <el-checkbox-group v-model="formInline.checkboxGroup4" size="mini">
                            <el-checkbox-button v-for="city in cities" :label="city" :key="city">{{city}}</el-checkbox-button>
                        </el-checkbox-group>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <div class="selectWrap">
            <div>
                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                <i class="el-icon-close"></i>
            </div>
            <div>
                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                <i class="el-icon-close"></i>
            </div>
            <div>
                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                <i class="el-icon-close"></i>
            </div>
            <div>
                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                <i class="el-icon-close"></i>
            </div>
            <div>
                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                <i class="el-icon-close"></i>
            </div>
            <div>
                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                <i class="el-icon-close"></i>
            </div>
            <span>已选择 5/5</span>
        </div>
        <div class="table">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" height="430">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column label="日期" width="120">
                    <template slot-scope="scope">{{ scope.row.date }}</template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"  width="120"> </el-table-column>
                <el-table-column prop="address" label="地址" show-overflow-tooltip> </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange"  @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[100, 200, 300, 400]" :page-size="100"
            layout="total, sizes, prev, pager, next, jumper" :total="400">
            </el-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                formInline:{checkboxGroup4:[]},
                cities:['上海', '北京', '广州', '深圳'],
                tableData: [{
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                    }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                    }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                    }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                    }, {
                    date: '2016-05-08',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                    }, {
                    date: '2016-05-06',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                    }, {
                    date: '2016-05-07',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }],
                currentPage:1
            }
        },
        methods: {
            handleSelectionChange(v){

            },
            handleCurrentChange(){

            },
            handleSizeChange(){

            },
        },
    }
</script>
<style lang="scss">
    .selectGoodsWrap{
        background: #fff;
        padding: 20px;
        .table{
            padding: 20px 0;
            padding-bottom: 0;
            .el-table{
                margin-bottom: 20px;
            }
        }
        .el-checkbox-button__inner{
            border-left: 1px solid #DCDFE6;
            margin-right: 5px;
        }
        .title{
            margin-bottom: 20px;
        }
        .selectWrap{
            display: flex;
            align-items: center;
            padding: 15px;
            background: rgb(230, 241, 255);
            border: 1px solid rgb(217, 235, 254);
            div{
                margin-right: 15px;
                width: 40px;
                height: 40px;
                position: relative;
                i{
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    width: 16px;
                    height: 16px;
                    background: #333;
                    color: #fff;
                    border-radius: 50%;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                }
                .el-image{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
</style>