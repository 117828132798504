<template>
    <div class="cardMsgWrap">
        <div class="cardTitle">
            <p class="name">【カジュアルアウター】柔らかく肌触りの良い綿素材を使用、軽い着心地、豊かな風合い</p>
            <span class="adCardMutiStatus">去逛逛</span>
        </div>
        <div class="cardMsg">5980</div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                
            }
        },
    }
</script>
<style lang="scss" scoped>
.cardMsgWrap{
    padding: 10px 0;
    margin-top: 10px;
    .cardMsg{
        font-size: 15px;
        margin-top: 10px;
    }
}
    .cardTitle{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666;
        .name{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .adCardMutiStatus{
            border: 1px solid #ddd;
            flex: 0 0 60px;
            text-align: center;
            padding: 3px;
        }
    }
</style>