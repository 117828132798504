<template>
    <div class="adItemWrap">
        <div class="topBar">
            <p class="name">广告一</p>
            <div class="tools">
                <el-tooltip class="item" effect="dark" content="切换单图/轮播" placement="top-start">
                    <!-- <el-button >上左</el-button> -->
                    <i class="el-icon-copy-document"></i>
                </el-tooltip>
                <i class="el-icon-unlock"></i>
                <i class="el-icon-lock"></i>
                <i class="el-icon-delete"></i>
            </div>
        </div>
        <div class="adsItemWrap" >
            <div class="adCardTitle">
                <div class="img">
                    <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                </div>
                <div class="msg">
                    <p>ki-ki-town</p>
                    <div>赞助内容</div>
                </div>
            </div>
            <div class="adContent">
                Best pick from #hakutou-shop0【カジュアルシューズ】本革　撥水　屈曲性抜群　どんな場合
            </div>
            <div class="adsImageWrap">
                <div class="adsImg">
                    <div class="adImage">
                        <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                    </div>
                    <card-msg></card-msg>
                </div>
                <!-- <div class="adsBanner">
                    <el-carousel height="360px">
                        <el-carousel-item v-for="item in 4" :key="item" :autoplay="false">
                            <div class="adImage">
                                <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
                            </div>
                            <card-msg></card-msg>
                        </el-carousel-item>
                    </el-carousel>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import cardMsg from './cardMsg'
    export default {
        data() {
            return {
                
            }
        },
        components: {
            cardMsg
        }
    }
</script>
<style lang="scss">
    .adItemWrap{
        // border: 1px solid #ddd;
        // padding: 20px;
        margin: 5px;
        width: 280px;
        .adsItemWrap{
            border: 2px solid #ddd;
            height: 460px;
            padding: 10px;
            box-sizing: border-box;

            .adContent{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-size: 12px;
                color: #666;
                margin-bottom: 10px;
                line-height: 1.5;
            }
            .adImage{
                width: 256px;
                height: 256px;
                .el-image{
                    width: 100%;
                    height: 100%;
                }
            }
            .adCardTitle{
                padding: 10px;
                display: flex;
                align-items: center;
                .img{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 5px;
                    .el-image{
                        width: 100%;
                        height: 100%;
                    }
                }
                .msg{
                    font-size: 12px;
                    div{
                        color: #666;
                    }
                }
            }
        }
        .topBar{
            display: flex;
            padding: 10px 0;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            .name{
                font-weight: bold;
            }
            .tools{
                i{
                    margin-left: 5px;
                }
            }
        }
    }
</style>